export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    BetterWayTopRightModule: ['Calculator', 'Search', 'SendToCountryModule'],
    CalculatorPageModulesItem: [
      'BetterWayTop',
      'Correspondents',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
    ],
    Entry: [
      'AccountDuplicationPage',
      'AppBanner',
      'AppReview',
      'AppReviews',
      'BackToSchool',
      'BackToSchoolCostCategory',
      'BackToSchoolCountryData',
      'BetterWayTop',
      'BlogContent',
      'BodyText',
      'Calculator',
      'CalculatorAppLeadingLinks',
      'CalculatorPage',
      'Card',
      'Cards',
      'CexChartFilterOptions',
      'CexCurrency',
      'CexCurrencyCorridor',
      'CexLineChart',
      'CexRateAlerts',
      'CexRateTable',
      'CexSearch',
      'ComparisonTable',
      'ComparisonTableCell',
      'ComparisonTableRow',
      'ContextualPromoBanner',
      'CorrespondentLocations',
      'Correspondents',
      'Country',
      'CurrencyPricingConverter',
      'EmbeddedTableEntry',
      'ExternalScript',
      'FaqFeedbackModule',
      'FaqFeedbackModuleItem',
      'FaqHero',
      'FaqModule',
      'FaqModuleItem',
      'FaqPopularQuestions',
      'FaqSearchHelp',
      'FaqSearchHelpItems',
      'FaqTopicsModule',
      'FaqTopicsModuleItems',
      'FeatureFlag',
      'Footer',
      'FooterNavigationGroup',
      'FooterNavigationGroupExtras',
      'ForgottenPasswordPage',
      'FullWidthBanner',
      'GenericErrorPage',
      'HeroSlot',
      'HowDoesItWork',
      'HowItWorksStepRelaunch',
      'HowSendingWorks',
      'IconWithText',
      'Image',
      'InformationDialog',
      'JazzCashPage',
      'JazzCashRequest',
      'LayoutFooter',
      'LayoutHeader',
      'LegalMessaging',
      'Link',
      'ListOfCountries',
      'LoginPage',
      'Logo',
      'Member',
      'MembersList',
      'Messages',
      'MfaFactors',
      'MfaVerification',
      'MobileAppLeadingLinks',
      'MobileStoreData',
      'MoneyRequestConfirmationDialog',
      'MoneyRequestPay',
      'MoneyRequestPaymentReason',
      'MoneyTransfer',
      'MoneyTransferCard',
      'NotFoundContent',
      'NotFoundErrorPage',
      'NotFoundPage',
      'OnfidoErrorPage',
      'OurContactInformation',
      'Page',
      'PageSection',
      'PaymentReason',
      'PayoutMethod',
      'PayoutMethodAlert',
      'Person',
      'PhoneNumberVerificationPage',
      'PopularCountries',
      'PriceComparsion',
      'PricingCalculator',
      'PromoApplicability',
      'PromoMessage',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
      'Redirect',
      'ReferAFriendGreenfieldEnabledCountries',
      'ReferFriendHeader',
      'RegionCode',
      'Regulator',
      'RequestMoneyNewRequestEmail',
      'RequestMoneyPage',
      'Review',
      'Reviews',
      'ReviewsTimeDictionary',
      'RobotsConfiguration',
      'Search',
      'SendToCountryModule',
      'SenderFormPage',
      'SendingPartnership',
      'SeoContactPoint',
      'SeoOrganizationStructuredData',
      'SeoSameAs',
      'ShareSocialLink',
      'SignIn',
      'Sitemap',
      'SmsVerificationCodePage',
      'SocialProofingModule',
      'SolutionsModule',
      'Step',
      'StepByStep',
      'StickyBanner',
      'TagLinks',
      'TextMeTheAppModule',
      'TextSection',
      'TextWithColor',
      'VerificationInProgress',
      'Video',
      'WeAreRegulated',
      'WebForm',
      'WhyChoose',
      'WhyChooseReason',
    ],
    HowDoesItWorkAnimationStepsItem: ['HowItWorksStepRelaunch'],
    LayoutHeaderLoggedInMenuItemsItem: ['Link'],
    LayoutHeaderNotLoggedInMenuItemsItem: ['Link'],
    LayoutHeaderReferFriendLink: ['Link'],
    LayoutHeaderUserDropdownMenuItemsItem: ['Link'],
    LogoLink: ['Link', 'Page'],
    PageSectionModulesItem: [
      'BodyText',
      'ComparisonTable',
      'IconWithText',
      'Reviews',
      'StepByStep',
      'TextWithColor',
      'Video',
      'WhyChoose',
    ],
    ResourceLink: [
      'AccountDuplicationPageDescriptionResourcesBlock',
      'AccountDuplicationPageDescriptionResourcesHyperlink',
      'AccountDuplicationPageDescriptionResourcesInline',
      'AccountDuplicationPageSubDescriptionResourcesBlock',
      'AccountDuplicationPageSubDescriptionResourcesHyperlink',
      'AccountDuplicationPageSubDescriptionResourcesInline',
      'BackToSchoolIncomesMessageResourcesBlock',
      'BackToSchoolIncomesMessageResourcesHyperlink',
      'BackToSchoolIncomesMessageResourcesInline',
      'BackToSchoolItemsListMessageResourcesBlock',
      'BackToSchoolItemsListMessageResourcesHyperlink',
      'BackToSchoolItemsListMessageResourcesInline',
      'BackToSchoolMapSectionMessageResourcesBlock',
      'BackToSchoolMapSectionMessageResourcesHyperlink',
      'BackToSchoolMapSectionMessageResourcesInline',
      'BetterWayTopHeadingResourcesBlock',
      'BetterWayTopHeadingResourcesHyperlink',
      'BetterWayTopHeadingResourcesInline',
      'BetterWayTopSubheadingResourcesBlock',
      'BetterWayTopSubheadingResourcesHyperlink',
      'BetterWayTopSubheadingResourcesInline',
      'BlogContentContentResourcesBlock',
      'BlogContentContentResourcesHyperlink',
      'BlogContentContentResourcesInline',
      'BodyTextContentResourcesBlock',
      'BodyTextContentResourcesHyperlink',
      'BodyTextContentResourcesInline',
      'BodyTextContentTwoResourcesBlock',
      'BodyTextContentTwoResourcesHyperlink',
      'BodyTextContentTwoResourcesInline',
      'BodyTextHeadingResourcesBlock',
      'BodyTextHeadingResourcesHyperlink',
      'BodyTextHeadingResourcesInline',
      'CexCurrencyDescriptionResourcesBlock',
      'CexCurrencyDescriptionResourcesHyperlink',
      'CexCurrencyDescriptionResourcesInline',
      'CexCurrencyFaqContentResourcesBlock',
      'CexCurrencyFaqContentResourcesHyperlink',
      'CexCurrencyFaqContentResourcesInline',
      'CexRateTableTitleResourcesBlock',
      'CexRateTableTitleResourcesHyperlink',
      'CexRateTableTitleResourcesInline',
      'CexSearchTitleTemplateResourcesBlock',
      'CexSearchTitleTemplateResourcesHyperlink',
      'CexSearchTitleTemplateResourcesInline',
      'ComparisonTableContentResourcesBlock',
      'ComparisonTableContentResourcesHyperlink',
      'ComparisonTableContentResourcesInline',
      'ContextualPromoBannerDescriptionResourcesBlock',
      'ContextualPromoBannerDescriptionResourcesHyperlink',
      'ContextualPromoBannerDescriptionResourcesInline',
      'EmbeddedTableEntryDescriptionResourcesBlock',
      'EmbeddedTableEntryDescriptionResourcesHyperlink',
      'EmbeddedTableEntryDescriptionResourcesInline',
      'FaqModuleItemDescriptionResourcesBlock',
      'FaqModuleItemDescriptionResourcesHyperlink',
      'FaqModuleItemDescriptionResourcesInline',
      'ForgottenPasswordPageHelpTextResourcesBlock',
      'ForgottenPasswordPageHelpTextResourcesHyperlink',
      'ForgottenPasswordPageHelpTextResourcesInline',
      'FullWidthBannerTextResourcesBlock',
      'FullWidthBannerTextResourcesHyperlink',
      'FullWidthBannerTextResourcesInline',
      'GenericErrorPageDescriptionResourcesBlock',
      'GenericErrorPageDescriptionResourcesHyperlink',
      'GenericErrorPageDescriptionResourcesInline',
      'GenericErrorPageSubDescriptionResourcesBlock',
      'GenericErrorPageSubDescriptionResourcesHyperlink',
      'GenericErrorPageSubDescriptionResourcesInline',
      'InformationDialogContentResourcesBlock',
      'InformationDialogContentResourcesHyperlink',
      'InformationDialogContentResourcesInline',
      'LegalMessagingContentResourcesBlock',
      'LegalMessagingContentResourcesHyperlink',
      'LegalMessagingContentResourcesInline',
      'LoginPageSsoWarningResourcesBlock',
      'LoginPageSsoWarningResourcesHyperlink',
      'LoginPageSsoWarningResourcesInline',
      'MemberDescriptionResourcesBlock',
      'MemberDescriptionResourcesHyperlink',
      'MemberDescriptionResourcesInline',
      'MoneyRequestConfirmationDialogContentResourcesBlock',
      'MoneyRequestConfirmationDialogContentResourcesHyperlink',
      'MoneyRequestConfirmationDialogContentResourcesInline',
      'MoneyTransferRichHeaderResourcesBlock',
      'MoneyTransferRichHeaderResourcesHyperlink',
      'MoneyTransferRichHeaderResourcesInline',
      'MoneyTransferRichSubheaderResourcesBlock',
      'MoneyTransferRichSubheaderResourcesHyperlink',
      'MoneyTransferRichSubheaderResourcesInline',
      'NotFoundContentMessageResourcesBlock',
      'NotFoundContentMessageResourcesHyperlink',
      'NotFoundContentMessageResourcesInline',
      'NotFoundErrorPageDescriptionResourcesBlock',
      'NotFoundErrorPageDescriptionResourcesHyperlink',
      'NotFoundErrorPageDescriptionResourcesInline',
      'NotFoundErrorPageSubDescriptionResourcesBlock',
      'NotFoundErrorPageSubDescriptionResourcesHyperlink',
      'NotFoundErrorPageSubDescriptionResourcesInline',
      'NotFoundPageMessageResourcesBlock',
      'NotFoundPageMessageResourcesHyperlink',
      'NotFoundPageMessageResourcesInline',
      'OurContactInformationNumbersAccordionContentResourcesBlock',
      'OurContactInformationNumbersAccordionContentResourcesHyperlink',
      'OurContactInformationNumbersAccordionContentResourcesInline',
      'PageContentResourcesBlock',
      'PageContentResourcesHyperlink',
      'PageContentResourcesInline',
      'PayoutMethodAlertMessageResourcesBlock',
      'PayoutMethodAlertMessageResourcesHyperlink',
      'PayoutMethodAlertMessageResourcesInline',
      'PromoMessageMessageResourcesBlock',
      'PromoMessageMessageResourcesHyperlink',
      'PromoMessageMessageResourcesInline',
      'PromotionalBannerDescriptionResourcesBlock',
      'PromotionalBannerDescriptionResourcesHyperlink',
      'PromotionalBannerDescriptionResourcesInline',
      'RegulatorContentResourcesBlock',
      'RegulatorContentResourcesHyperlink',
      'RegulatorContentResourcesInline',
      'SendToCountryModuleDescriptionResourcesBlock',
      'SendToCountryModuleDescriptionResourcesHyperlink',
      'SendToCountryModuleDescriptionResourcesInline',
      'SenderFormPageDisclaimerResourcesBlock',
      'SenderFormPageDisclaimerResourcesHyperlink',
      'SenderFormPageDisclaimerResourcesInline',
      'SmsVerificationCodePageDescriptionResourcesBlock',
      'SmsVerificationCodePageDescriptionResourcesHyperlink',
      'SmsVerificationCodePageDescriptionResourcesInline',
      'SocialProofingModuleSubtitleResourcesBlock',
      'SocialProofingModuleSubtitleResourcesHyperlink',
      'SocialProofingModuleSubtitleResourcesInline',
      'SolutionsModuleModalResourcesBlock',
      'SolutionsModuleModalResourcesHyperlink',
      'SolutionsModuleModalResourcesInline',
      'StepDescriptionResourcesBlock',
      'StepDescriptionResourcesHyperlink',
      'StepDescriptionResourcesInline',
      'StickyBannerDescriptionResourcesBlock',
      'StickyBannerDescriptionResourcesHyperlink',
      'StickyBannerDescriptionResourcesInline',
      'TextSectionContentResourcesBlock',
      'TextSectionContentResourcesHyperlink',
      'TextSectionContentResourcesInline',
      'TextWithColorTextResourcesBlock',
      'TextWithColorTextResourcesHyperlink',
      'TextWithColorTextResourcesInline',
      'VideoContentResourcesBlock',
      'VideoContentResourcesHyperlink',
      'VideoContentResourcesInline',
      'WebFormDescriptionResourcesBlock',
      'WebFormDescriptionResourcesHyperlink',
      'WebFormDescriptionResourcesInline',
    ],
    _Node: [
      'AccountDuplicationPage',
      'AppBanner',
      'AppReview',
      'AppReviews',
      'BackToSchool',
      'BackToSchoolCostCategory',
      'BackToSchoolCountryData',
      'BetterWayTop',
      'BlogContent',
      'BodyText',
      'Calculator',
      'CalculatorAppLeadingLinks',
      'CalculatorPage',
      'Card',
      'Cards',
      'CexChartFilterOptions',
      'CexCurrency',
      'CexCurrencyCorridor',
      'CexLineChart',
      'CexRateAlerts',
      'CexRateTable',
      'CexSearch',
      'ComparisonTable',
      'ComparisonTableCell',
      'ComparisonTableRow',
      'ContextualPromoBanner',
      'CorrespondentLocations',
      'Correspondents',
      'Country',
      'CurrencyPricingConverter',
      'EmbeddedTableEntry',
      'ExternalScript',
      'FaqFeedbackModule',
      'FaqFeedbackModuleItem',
      'FaqHero',
      'FaqModule',
      'FaqModuleItem',
      'FaqPopularQuestions',
      'FaqSearchHelp',
      'FaqSearchHelpItems',
      'FaqTopicsModule',
      'FaqTopicsModuleItems',
      'FeatureFlag',
      'Footer',
      'FooterNavigationGroup',
      'FooterNavigationGroupExtras',
      'ForgottenPasswordPage',
      'FullWidthBanner',
      'GenericErrorPage',
      'HeroSlot',
      'HowDoesItWork',
      'HowItWorksStepRelaunch',
      'HowSendingWorks',
      'IconWithText',
      'Image',
      'InformationDialog',
      'JazzCashPage',
      'JazzCashRequest',
      'LayoutFooter',
      'LayoutHeader',
      'LegalMessaging',
      'Link',
      'ListOfCountries',
      'LoginPage',
      'Logo',
      'Member',
      'MembersList',
      'Messages',
      'MfaFactors',
      'MfaVerification',
      'MobileAppLeadingLinks',
      'MobileStoreData',
      'MoneyRequestConfirmationDialog',
      'MoneyRequestPay',
      'MoneyRequestPaymentReason',
      'MoneyTransfer',
      'MoneyTransferCard',
      'NotFoundContent',
      'NotFoundErrorPage',
      'NotFoundPage',
      'OnfidoErrorPage',
      'OurContactInformation',
      'Page',
      'PageSection',
      'PaymentReason',
      'PayoutMethod',
      'PayoutMethodAlert',
      'Person',
      'PhoneNumberVerificationPage',
      'PopularCountries',
      'PriceComparsion',
      'PricingCalculator',
      'PromoApplicability',
      'PromoMessage',
      'PromotionalBanner',
      'PromotionalBannerDisclaimer',
      'Redirect',
      'ReferAFriendGreenfieldEnabledCountries',
      'ReferFriendHeader',
      'RegionCode',
      'Regulator',
      'RequestMoneyNewRequestEmail',
      'RequestMoneyPage',
      'Review',
      'Reviews',
      'ReviewsTimeDictionary',
      'RobotsConfiguration',
      'Search',
      'SendToCountryModule',
      'SenderFormPage',
      'SendingPartnership',
      'SeoContactPoint',
      'SeoOrganizationStructuredData',
      'SeoSameAs',
      'ShareSocialLink',
      'SignIn',
      'Sitemap',
      'SmsVerificationCodePage',
      'SocialProofingModule',
      'SolutionsModule',
      'Step',
      'StepByStep',
      'StickyBanner',
      'TagLinks',
      'TextMeTheAppModule',
      'TextSection',
      'TextWithColor',
      'VerificationInProgress',
      'Video',
      'WeAreRegulated',
      'WebForm',
      'WhyChoose',
      'WhyChooseReason',
    ],
  },
};
export default result;
