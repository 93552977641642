export * from './query-params';
export * from './regex';
export * from './contentful';

export const DEFAULT_404_PAGE_SLUG = '404';
export const DEFAULT_500_PAGE_SLUG = '500';
export const SITEMAP_SLUG = 'sitemap';
export const GENERIC_CLP_SLUG = 'generic';
export const DEFAULT_COUNTRY_PAGE = 'countries';
export const REFER_A_FRIEND_SLUG = 'refer-a-friend';

export const DEFAULT_LOCATION = 'us';
export const DEFAULT_COUNTRY_NAME = 'united-states';
export const DEFAULT_SOCIAL_IMAGE_PATH = '/social-images/logo-with-bg.jpg';
export const GTM_INITIAL_VALUE = 'information not available';
export const STICKY_BANNERS_WRAPPER_ID = 'banners-wrapper';
export const PHILIPPINES_COUNTRY_CODE_FOR_AB_TEST = 'PH';
export const BLOG_CARDS_PER_PAGE = 12;

export const ANDROID = 'Android';
export const IOS = 'iOS';
export const AB_TESTING_HP_CLP_SLUG = 'goto'; // https://worldremit.atlassian.net/browse/CONWEB-2183

export const SENDWAVE = 'Sendwave';

export const JSON_TABS_INDENT = 2;

export const TRUSTPILOT_WIDGET_SCRIPT_URL =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

export const GOOGLE_RECAPTCHA_SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}`;
export const GOOGLE_RECAPTCHA_ASSESSMENT_API_URL = `https://recaptchaenterprise.googleapis.com/v1/projects/sendwave-main/assessments`;

export const SMART_LINK_URL = 'https://try.sendwave.com';
