import { createTheme, ThemeOptions } from '@mui/material/styles';
import { extLatinCharsRange, theme as defaultTheme } from '@wr/web-ui';

import { colors } from '../../styles';

// https://www.figma.com/file/m4mpQtyhdo5OIWXytwfuQm/Stylesheet
export const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: colors.sendwaveYellow,
      contrastText: colors.sendwaveBrown,
      dark: colors.sendwaveYellow,
    },
    secondary: {
      main: colors.sendwaveBrown,
      contrastText: colors.white,
      dark: colors.sendwaveBrown,
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: 'Inter, Arial',
    h1: {
      ...defaultTheme.typography.h1,
      color: colors.sendwaveBrown,
      fontFamily: 'Sendwavy, Inter, Arial',
      fontWeight: 400,
      fontSize: '60px',
      lineHeight: 1,
      textTransform: 'uppercase',
      wordSpacing: '0.1125em',
    },
    h2: {
      ...defaultTheme.typography.h2,
      color: colors.sendwaveBrown,
      fontFamily: 'Sendwavy, Inter, Arial',
      fontWeight: 400,
      fontSize: '40px',
      lineHeight: 1,
      textTransform: 'uppercase',
      wordSpacing: '0.1125em',
    },
    h3: {
      ...defaultTheme.typography.h3,
      color: colors.sendwaveBlack,
    },
    h4: {
      ...defaultTheme.typography.h4,
      color: colors.sendwaveBlack,
    },
    h5: {
      ...defaultTheme.typography.h5,
      color: colors.sendwaveBlack,
    },
    h6: {
      ...defaultTheme.typography.h6,
      color: colors.sendwaveBlack,
    },
    body2: {
      ...defaultTheme.typography.body2,
      color: colors.grey,
      lineHeight: '1.5',
    },
  },
  components: {
    ...defaultTheme.components,
    MuiCssBaseline: {
      styleOverrides: `
        body: {
          backgroundColor: #fff,
          lineHeight: 1.25,
          fontSize: 16,
        }
        @font-face {
          font-family: 'Sendwavy';
          font-style: normal;
          font-display: swap;
          font-weight: normal;
          src: local('Sendwavy'),
              url('/fonts/sendwavy/SENDWAVY-Regular.otf') format('opentype'),
              url('/fonts/sendwavy/SENDWAVY-Regular.ttf') format('truetype'),
              url('/fonts/sendwavy/SENDWAVY-Regular.woff') format('woff'),
              url('/fonts/sendwavy/SENDWAVY-Regular.woff2') format('woff2');
          unicodeRange: ${extLatinCharsRange};
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        ...defaultTheme.components.MuiButton.styleOverrides,
        root: {
          ...defaultTheme.components.MuiButton.styleOverrides.root,
          '&.Mui-focusVisible, &:active': {
            outline: 'none',
          },
          '&:hover': {
            backgroundColor: `${colors.sendwaveYellow}`,
          },
        },

        outlinedPrimary: {
          'border': `2px solid ${colors.sendwaveTeal}`,
          'color': colors.sendwaveTeal,
          'borderRadius': '28px',
          'fontSize': '16px',

          '&:hover': {
            border: `2px solid ${colors.sendwaveTeal}`,
            color: colors.sendwaveTeal,
            backgroundColor: colors.sendwaveTealLight,
          },
        },

        outlinedSecondary: {
          'color': colors.sendwaveBrown,
          'backgroundColor': colors.sendwaveYellow,
          'padding': '1rem',
          'borderRadius': '28px',
          'border': 'none',
          'fontSize': '16px',

          '&:hover': {
            border: 'none',
            color: colors.sendwaveBrown,
            backgroundColor: `${colors.sendwaveYellowLight} !important`,
          },
        },

        contained: {
          ...defaultTheme.components.MuiButton.styleOverrides.contained,
          '&:hover': {
            ...defaultTheme.components.MuiButton.styleOverrides.contained[
              '&:hover'
            ],
            color: colors.white,
          },
        },

        containedPrimary: {
          'borderRadius': '28px',
          'backgroundColor': colors.sendwaveYellow,
          'color': colors.sendwaveBrown,
          'fontSize': '16px',

          '&:hover': {
            color: colors.sendwaveBrown,
            backgroundColor: colors.sendwaveYellowLight,
          },
        },

        sizeSmall: {
          ...defaultTheme.components.MuiButton.styleOverrides.sizeSmall,
          'fontSize': '1.1rem',
          'fontWeight': '600',
          '&:hover': {
            background: 'none',
          },
          '&:active': {
            backgroundColor: colors.sendwaveBlackLight,
          },
        },

        text: {
          ...defaultTheme.components.MuiButton.styleOverrides.text,
          '&.Mui-focusVisible, &:active': {
            ...defaultTheme.components.MuiButton.styleOverrides.text[
              '&.Mui-focusVisible, &:active'
            ],
            boxShadow: `0 0 0 4px ${colors.sendwaveBlack}`,
          },
        },
        textPrimary: {
          ...defaultTheme.components.MuiButton.styleOverrides.textPrimary,
          '&:hover': {
            color: colors.sendwaveBlack,
          },
        },
        textSecondary: {
          ...defaultTheme.components.MuiButton.styleOverrides.textSecondary,
          'color': colors.sendwaveBlack,
          '&:hover': {
            ...defaultTheme.components.MuiButton.styleOverrides.textSecondary[
              '&:hover'
            ],
            backgroundColor: colors.sendwaveYellow,
          },
          '&.Mui-focusVisible, &:active': {
            ...defaultTheme.components.MuiButton.styleOverrides.textSecondary[
              '&.Mui-focusVisible, &:active'
            ],
            backgroundColor: colors.sendwaveYellowLight,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        ...defaultTheme.components.MuiMenu.styleOverrides,
        paper: {
          '&::-webkit-scrollbar': {
            ...defaultTheme.components.MuiMenu.styleOverrides.paper[
              '&::-webkit-scrollbar'
            ],
          },
          '&::-webkit-scrollbar-track': {
            ...defaultTheme.components.MuiMenu.styleOverrides.paper[
              '&::-webkit-scrollbar-track'
            ],
          },
          '&::-webkit-scrollbar-thumb': {
            ...defaultTheme.components.MuiMenu.styleOverrides.paper[
              '&::-webkit-scrollbar-thumb'
            ],
            backgroundColor: colors.sendwaveBlack,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        ...defaultTheme.components.MuiFormLabel.styleOverrides,
        root: {
          ...defaultTheme.components.MuiFormLabel.styleOverrides.root,
          'color': colors.sendwaveBlack,
          '&.Mui-focused': {
            color: colors.sendwaveBlack,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        ...defaultTheme.components.MuiOutlinedInput.styleOverrides,
        root: {
          ...defaultTheme.components.MuiOutlinedInput.styleOverrides.root,
          'color': colors.sendwaveBlack,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.sendwaveBlack,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline.Mui-focused, &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.sendwaveBlack,
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.sendwaveBlack,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: colors.sendwaveOrange,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: colors.sendwaveOrange,
          },
        },
      },
    },
  },
  customComponents: {
    ...defaultTheme.customComponents,
    appDownloadDropdown: {
      shadowColor: colors.greyLight,
      backgroundColor: colors.sendwaveYellow,
      color: colors.sendwaveBrown,
      dark: {
        shadowColor: colors.sendwaveBlackLight,
        backgroundColor: colors.sendwaveBrown,
        color: colors.sendwaveYellow,
      },
    },
    card: {
      ...defaultTheme.customComponents.card,
      backgroundColor: colors.white,
      border: '1px solid rgba(0, 0, 0, 0.25)',
      borderRadius: '8px',
      textContainer: {
        color: colors.sendwaveBlack,
      },
      description: {
        color: colors.grey,
      },
      ctaButton: {
        ...defaultTheme.customComponents.card.ctaButton,
        'backgroundColor': colors.sendwaveYellow,
        'color': colors.sendwaveBrown,
        'border': 'none',
        '&:hover': {
          backgroundColor: colors.white,
        },
        'fontSize': '16px',
      },
      title: {
        color: colors.sendwaveBlack,
      },
    },
    header: {
      headerWrapper: {
        backgroundColor: colors.white,
        dark: {
          backgroundColor: colors.sendwaveYellow,
        },
      },
      divider: {
        backgroundColor: colors.black,
      },
      menuItem: {
        borderTopColor: colors.sendwaveBlack,
      },
      logo: {
        height: 32,
        breakpointsUpSm: {
          height: 25,
        },
      },
      navLink: {
        color: colors.sendwaveTeal,
        textDecoration: 'underline',
        fontSize: '16px',
        dark: {
          color: colors.sendwaveBrown,
        },
        hover: {
          color: colors.sendwaveTeal,
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
      },
      selectButtonText: {
        color: colors.sendwaveTeal,
        textDecoration: 'underline',
        fontSize: '16px',
        dark: {
          color: colors.sendwaveBrown,
        },
        hover: {
          color: colors.sendwaveTeal,
          textDecoration: 'underline',
        },
      },
    },
    calculator: {
      exchangeRate: {
        textColor: colors.sendwaveBrown,
        strikeThroughColor: colors.sendwaveGray,
      },
      countryField: {
        borderRadius: 10,
        labelColor: colors.sendwaveGray,
        backgroundColor: colors.sendwaveYellow,
        selectColor: colors.sendwaveBrown,
        inputColor: colors.sendwaveBrown,
      },
      autocomplete: {
        textColor: colors.sendwaveBrown,
        borderColor: colors.sendwaveGray,
      },
    },
    listOfCountries: {
      section: {
        backgroundColor: colors.white,
      },
      countryLink: {
        backgroundColor: colors.white,
        borderRadius: '8px',
        boxShadow: '0px 2px 8px 0px rgba(91, 102, 112, 0.16)',
        hover: {
          backgroundColor: colors.sendwaveGray,
        },
      },
      subheader: {
        color: colors.grey,
      },
      heading: {
        color: colors.sendwaveBrown,
      },
    },
    textSection: {
      flexDirection: 'column-reverse',
      textSectionColumn: {
        breakpointsUpMd: {
          height: '40rem',
          alignItems: 'center',
        },
      },
      textSectionContent: {
        textAlign: 'center',
      },
      textSectionImage: {
        breakpointsSm: {
          height: '320px',
        },
        breakpointsMdUp: {
          height: '640px',
        },
      },
    },
    stepByStep: {
      ...defaultTheme.customComponents.stepByStep,
      listItem: {
        backgroundColor: colors.sendwaveBlackLight,
      },
      stepHeading: {
        color: colors.sendwaveBlack,
      },
    },
    footer: {
      ...defaultTheme.customComponents.footer,
      backgroundColor: colors.sendwaveBrown,
      navigationLink: {
        color: colors.white,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
      navigationGroupHeading: {
        fontFamily: 'Inter, Arial',
        fontSize: '28px',
        fontWeight: 800,
      },
    },
    fullWidthBanner: {
      section: {
        breakpointSm: {
          flexDirection: 'column',
        },
        breakpointMdUp: {
          flexDirection: 'column-reverse',
        },
      },
      container: {
        breakpointSm: {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
      borderContainer: {
        padding: `${createTheme().spacing(3)} ${createTheme().spacing(2)}`,
      },
    },
    search: {
      option: {
        borderTopColor: colors.greyLight,
      },
      link: {
        color: colors.sendwaveBlack,
        textDecorationColor: colors.sendwaveBlack,
      },
    },
  },
};

export const sendwaveMuiTheme = createTheme(theme as ThemeOptions);
